(function(){"use strict";class c{constructor(){this.data={}}set(e,o){this.data[e]=o}get(e){return this.data[e]}}const u={Booster:class{constructor(e){this.options=e}log(e,o){const s=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,a=[`%c[${this.options.title}] ${e}. Link to documentation ${this.options.documentationLink}`,s];o?(console.group(...a),Array.isArray(o)?console.log(...o):console.log(o),console.groupEnd()):console.log(...a)}validate(e,o,s){if(!e.validate)return!0;if(typeof e.validate=="function"){if(!e.validate(s))return this.log(`Invalid value "${s}" for attribute "${o}"`),!1}else if(!e.validate.includes(s))return this.log(`Invalid value "${s}" for attribute "${o}"`,[`%cPossible values:%c
`+e.validate.map(a=>`• ${a}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(e){const o=new c;for(const s in this.options.attributes){const a=this.options.attributes[s],i=e.getAttribute(s);if(!i){o.set(s,a.defaultValue);continue}if(!this.validate(a,s,i))continue;let n=i;a.parse&&(n=a.parse(i)??a.defaultValue),o.set(s,n)}this.options.apply.call(this,e,o)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(o=>this.parse(o))}},parse:{stringToBoolean:t=>t!=="false"},validation:{isBoolean:t=>/^(true|false)$/.test(t),isNumber:t=>!isNaN(Number(t))}};var r=(t=>(t.Facebook="facebook",t.Linkedin="linkedin",t.Pinterest="pinterest",t.Telegram="telegram",t.Twitter="twitter",t.Whatsapp="whatsapp",t))(r||{});const p=new u.Booster({name:"fb-social",attributes:{"fb-social-type":{defaultValue:void 0,validate:Object.values(r)}},apply(t,e){if(t.tagName.toLowerCase()!=="a")return this.log("Unsupported HTML tag detected. Use <a> tag instead");const s=e.get("fb-social-type");if(!s)return;const a=encodeURIComponent(document.title),i=encodeURIComponent(window.location.href);let n="#";switch(s){case"facebook":n=`https://www.facebook.com/sharer/sharer.php?u=${i}`;break;case"linkedin":n=`https://www.linkedin.com/sharing/share-offsite/?url=${i}`;break;case"pinterest":n=`https://pinterest.com/pin/create/button/?url=${i}&description=${a}`;break;case"telegram":n=`https://t.me/share/url?url=${i}&text=${a}`;break;case"twitter":n=`https://twitter.com/intent/post/?url=${i}&text=${a}`;break;case"whatsapp":n=`https://api.whatsapp.com/send/?text=${a}%20${i}`;break}t.setAttribute("href",n),t.setAttribute("target","_blank")},title:"Social Share Booster",documentationLink:"https://www.flowbase.co/booster/social-share"}),l=()=>p.init();document.readyState==="complete"?l():window.addEventListener("load",l)})();
